.layout-wrapper {
    .layout-footer {
        font-weight: 700;
        background-color: $menuBgColor;
        padding: 20px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .footer-text-left {
            float: left;
        }

        .footer-text-right {
            float: right;
        }
    }
}
