$primaryColor: #39a3f4;
$primaryLightColor: scale-color($primaryColor, $lightness: 30%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff;
$primaryLighterColor: rgba($primaryLightColor,.2);

$highlightBg: $primaryColor;
$highlightTextColor: $primaryTextColor;

@import '../sass/theme/_theme_dark.scss';
