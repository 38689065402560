@mixin border-radius($val) {
    -moz-border-radius: $val;
    -webkit-border-radius: $val;
    border-radius: $val;
}

@mixin border-radius-right($val) {
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin border-radius-left($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
}

@mixin border-radius-top($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
}

@mixin border-radius-bottom($val) {
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin gradient($deg, $color1, $color2) {
    background: -moz-linear-gradient($deg, $color1 0%, $color2 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, $color1), color-stop(100%, $color2)); /* safari4+,chrome */
    background: -webkit-linear-gradient($deg, $color1 0%, $color2 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient($deg, $color1 0%, $color2 100%); /* opera 11.10+ */
    background: -ms-linear-gradient($deg, $color1 0%, $color2 100%); /* ie10+ */
    background: linear-gradient($deg, $color1 0%, $color2 100%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 ); /* ie6-9 */
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin border-shadow() {
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

@mixin overlay-shadow {
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 4px rgba(0, 0, 0, 0.3);
}

@mixin no-shadow() {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

@mixin shadow($value) {
    box-shadow: $value;
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
}

@mixin border-box() {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;
    -moz-box-sizing: $box-model;
    box-sizing: $box-model;
}

@mixin list-item-hover {
    background-color: lighten($primaryColor, 25%);
    color: $listItemHoverTextColor;
}

@mixin highlight {
    background-color: $primaryColor;
    color: $primaryTextColor;
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -o-transform: rotate($deg);
    -ms-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin scale($deg) {
    -webkit-transform: scale($deg);
    -moz-transform: scale($deg);
    -o-transform: scale($deg);
    -ms-transform: scale($deg);
    transform: scale($deg);
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin menu-item-hover {
    background-color: $primaryColor;
    color: $primaryTextColor;
}

@mixin focused-input() {
	border-color: $primaryColor;
    @include shadow(0 0 7px 0 $primaryColor);
}

@mixin animation-duration($val) {
    -webkit-animation-duration: $val;
    -moz-animation-duration: $val;
    animation-duration: $val;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}
@mixin circle($color){
  width: 10.714em;
  height: 10.714em;
  border: 10px solid #b2b2b2;
  border-right-color: $color;
  border-top-color: $color;
  border-bottom-color: $color;
  border-radius: 50%;
  margin: auto;
}
@mixin circle-icon($color){
  position: relative;
  font-size: 24px;
  left: 50%;
  top: 20%;
  width: 36px;
  height: 36px;
  margin-left: -20px;
  color: $color;
  text-align: center;

}
@mixin circle-text($color){
  width: 79px;
  height: 50px;
  position: relative;
  vertical-align: middle;
  text-align: center;
  top: 40%;
  left: 50%;
  margin-left: -35px;
  color: $color;
  font-weight: bold;
  font-size: 30px;
}
@mixin flex() {
    display: -ms-flexbox;
    display: flex;
}

@mixin flex-wrap($value) {
    -ms-flex-wrap: $value;
    flex-wrap: $value;
}

@mixin flex-direction-row() {
    -ms-flex-direction: row;
    flex-direction: row;
}

@mixin flex-direction-column() {
    -ms-flex-direction: column;
    flex-direction: column;
}

@mixin flex-justify-center() {
    -ms-flex-pack: center;
    justify-content: center;
}

@mixin flex-justify-between() {
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@mixin flex-align-center() {
    -ms-flex-align: center;
    align-items: center;
}

@mixin flex-align-start() {
    -ms-flex-align: start;
    align-items: flex-start;
}

@mixin flex-align-end() {
    -ms-flex-align: end;
    align-items: flex-end;
}

@mixin flex-justify-start() {
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@mixin flex-justify-end() {
    -ms-flex-pack: end;
    justify-content: flex-end;
}
