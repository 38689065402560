@media (max-width: 1024px) {
    .layout-wrapper {
        .topbar {
            box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);

            .profile {
                .username {
                  display: none;
                }
            }

            .menu-button {
                display: inline-block;
                margin-left: 10px;
            }

            .topbar-search {
                display: none;
            }

            .topbar-menu {

                > li.topbar-search-item {
                    display: block;
                }
            }

            .logo-link {
                width: auto;
            }
        }

        .layout-mask {
            display: none;
        }

        .layout-menu-container {
            padding: 0;
            width: 230px;
            height: 100%;
            overflow: auto;
            transform: translateX(-100%);
            transition: transform $transitionDuration;

            &.layout-menu-container-inactive {
                transform: translateX(-100%) !important;
            }

            .layout-menu-content {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .layout-menu-title {
                display: block;
                padding: 16px;
                font-size: 16px;
                color: $textSecondaryColor;
                border-bottom: 1px solid $dividerColor;
                letter-spacing: 2px;
            }

            .layout-menu {
                flex: 1 1 0;

                li {
                    a {
                        padding: 10px 12px;
                        display: block;

                        &:hover {
                            background-color: $primaryColor;
                            color: $primaryTextColor;
                        }

                        i:first-child {
                            font-size: 18px;
                            vertical-align: middle;
                            display: inline-block;
                        }

                        i.layout-menuitem-toggler {
                            float: right;
                            line-height: 22px;
                        }

                        > span {
                            vertical-align: middle;
                            display: inline-block;
                            line-height: $fontSize + 1;
                            margin-left: 6px;
                        }
                    }

                    &.layout-root-menuitem {
                        > .layout-menuitem-root-text {
                            display: block !important;
                        }

                        > a {
                            display: none !important;
                        }
                    }

                    &.active-menuitem {
                        > a {
                            background-color: $menuitemActiveBgColor;
                            color: $primaryColor;

                            &:hover {
                                background-color: $primaryColor;
                                color: $primaryTextColor;
                            }
                        }
                    }
                }

                > li {
                    &:first-child {
                        margin-top: 8px;
                    }

                    ul {
                        background: $menuBgColor;

                        li {
                            a {
                                padding-left: 24px;
                            }

                            ul {
                                li {
                                    a {
                                        padding-left: 36px;
                                    }

                                    ul {
                                        li {
                                            a {
                                                padding-left: 48px;
                                            }

                                            ul {
                                                li {
                                                    a {
                                                        padding-left: 60px;
                                                    }

                                                    ul {
                                                        li {
                                                            a {
                                                                padding-left: 72px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .layout-menu-footer {
                padding-bottom: 50px;

                .layout-menu-footer-title {
                    display: block;
                    padding: 16px;
                    font-size: 16px;
                    color: $textSecondaryColor;
                    border-bottom: 1px solid $dividerColor;
                    letter-spacing: 2px;
                }

                .layout-menu-footer-content {
                    padding: 10px 12px;
                    color: $textColor;

                    .p-progressbar {
                        height: 5px;
                        border: 0 none;
                        margin: 16px 0 4px 0;
                    }
                }
            }
        }

        &.layout-mobile-active {
            .layout-menu-container {
                transform: translateX(0);
            }

            .layout-mask {
                display: block;
                position: fixed;
                background-color: $bodyBgColor;
                @include opacity(.7);
                height: 100%;
                width: 100%;
                top: 50px;
                left: 0;
                z-index: 100;
            }
        }

        .layout-content {
            padding: 50px 0px 0px 0px;
        }
    }
}
