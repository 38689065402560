/* Utils */
.clearfix:after {
    content:" ";
    display:block;
    clear:both;
}

.card {
    background: $cardBgColor;
    padding: 1.5rem;
    margin-bottom: 1rem;
    border-radius: $borderRadius;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);

    &:last-child {
        margin: 0;
    }
}

.nopad {
    padding: 0;

    .p-panel-content {
        padding: 0;
    }
}

.p-sr-only {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}

.separator {
    border: 1px solid $dividerColor;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

::-webkit-input-placeholder {color:$textSecondaryColor}
:-moz-placeholder           {color:$textSecondaryColor}
::-moz-placeholder          {color:$textSecondaryColor}
:-ms-input-placeholder      {color:$textSecondaryColor}
