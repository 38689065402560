/* Exception */
.exception-body {
    height: 100vh;
    font-size: 16px;
    margin: 0;

    background: {
        position: top right;
        repeat: no-repeat;
        size: contain;
    }

    .exception-container{
        height: 100%;
        padding-left: 100px;

        img {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin: 100px 0;
        }

        h1 {
            font-size: 80px;
            color: $textColor;
        }

        p {
            color: $textColor;
            margin-top: 0;
            margin-bottom: 40px;
        }
    }
}

@media (max-width: 1280px) {
    .exception-body {
        background-size: cover;
        text-align: center;

        .exception-container {
            padding-left: 0;
            padding-top: 200px;

            img {
                margin-top: 0;
            }

            h1 {
                font-size: 40px;
            }

            p {
                padding: 0 25px;
            }
        }
    }
}
