.widget-overview-box {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
    padding: 15px;
    position: relative;
    min-height: 100px;
    overflow: hidden;

    h1 {
        font-size: 12px;
        color: #ffffff;
        margin: 0;
    }

    img {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .overview-value {
        font-size: 20px;
        font-weight: 700;
        color: #ffffff;
    }

    .overview-ratio {
        position: absolute;
        right: 15px;
        top: 15px;
        color: #ffffff;

        .overview-direction {
            display: inline-block;
            text-align: center;
            width: 20px;
            height: 20px;
            background-color: #ffffff;

            i {
                vertical-align: middle;
                font-size: inherit;
            }
        }

        .overview-ratio-value {
            display: inline-block;
            height: 20px;
            padding: 0 4px;
        }
    }

    &.overview-box-1 {
        background-image: linear-gradient(to bottom, #39a3f4 1%, #279cf5 99%);

        .overview-direction {
            color: #39a3f4;
        }

        .overview-ratio-value {
            background-color: #1164a3;
            color: #39a3f4;
        }
    }

    &.overview-box-2 {
        background-image: linear-gradient(to bottom, #76be45, #66b92d);

        .overview-direction {
            color: #76be45;
        }

        .overview-ratio-value {
            background-color: #447d1d;
            color: #76be45;
        }
    }

    &.overview-box-3 {
        background-image: linear-gradient(to bottom, #dea242, #d5942e);

        .overview-direction {
            color: #dea242;
        }

        .overview-ratio-value {
            background-color: #90631d;
            color: #dea242;
        }
    }

    &.overview-box-4 {
        background-image:linear-gradient(to bottom, #d67161, #d65c49);

        .overview-direction {
            color: #d67161;
        }

        .overview-ratio-value {
            background-color: #943728;
            color: #d67161;
        }
    }
}

@mixin circle($color){
    width: 10.714em;
    height: 10.714em;
    border: 10px solid $dividerColor;
    border-right-color: $color;
    border-top-color: $color;
    border-bottom-color: $color;
    border-left-color: $color;
    border-radius: 50%;
    margin: auto;
}
@mixin circle-icon($color){
    position: relative;
    font-size: 24px;
    left: 50%;
    top: 20%;
    width: 36px;
    height: 36px;
    margin-left: -20px;
    color: $color;
    text-align: center;
}

@mixin circle-text($color){
    width: 79px;
    height: 50px;
    position: relative;
    vertical-align: middle;
    text-align: center;
    top: 40%;
    left: 50%;
    margin-left: -35px;
    color: $color;
    font-weight: bold;
    font-size: 30px;
}

.widget-circle-panel {
    &.card {
        padding: 1rem 0 0 0;
    }

    h4 {
        text-align: center;
        margin-bottom: 0;
        padding-bottom: 1rem;
        border-bottom: 1px solid $dividerColor;
    }

    .grid > div {
        padding: 2em 0;
    }

    div.col-12:not(:last-child) {
        border-right: 1px solid $dividerColor;
    }

    .status-title {
        margin-bottom: 0.714em;
        font-size: 1.429em;
        text-align: center;
    }

    .circle1 {
        @include circle(#6ebc3b);
        border-left-color: $dividerColor;
        i {
            @include circle-icon(#6ebc3b);
        }
        span {
            @include circle-text(#6ebc3b)
        }
    }
    .circle2 {
        @include circle(#f6a821);
        border-bottom-color: $dividerColor;
        border-left-color: $dividerColor;
        border-right-color: $dividerColor;
        i {
            @include circle-icon(#f6a821);
        }
        span {
            @include circle-text(#f6a821)
        }
    }
    .circle3 {
        @include circle(#039ade);
        border-bottom-color: $dividerColor;
        border-right-color: $dividerColor;
        i {
            @include circle-icon(#039ade);
        }
        span {
            @include circle-text(#039ade)
        }
    }
    .circle4 {
        border-left-color: $dividerColor;
        @include circle(#d66351);
        i {
            @include circle-icon(#d66351);
        }
        span {
            @include circle-text(#d66351)
        }
    }
}

.widget-user-card {
    border: 1px solid $dividerColor;
    padding: 0;

    .user-card-header {
        height: 100px;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
        }

        .p-button {
            position: absolute;
            width: 36px;
            height: 36px;
            top: 32px;
            right: 24px;
        }

    }

    .user-card-content {
        min-height: 328px;
        background-color: transparent;
        position: relative;

        img {
            position: absolute;
            top: -90px;
            left: 24px;
        }

        .user-card-name {
            font-size: 20px;
            color: $textColor;
            position: absolute;
            top: -60px;
            margin-left: 110px;
            font-weight: 700;
            white-space: nowrap;
        }

        .user-detail {
            text-align: left;

            ul {
                padding: 0px 0 32px 0;
                margin: 0;
                list-style-type: none;

                li {
                    padding: 16px 24px;
                    border-top: 1px solid $dividerColor;

                    &:last-child {
                        border-bottom: 1px solid $dividerColor;
                    }

                    i {
                        font-size: 24px;
                        margin-right: 8px;
                        width: 32px;
                        vertical-align: middle;
                    }

                    .project-title {
                        font-weight: 700;
                        margin-right: 8px;
                    }

                    .project-detail {
                        color: $textSecondaryColor;
                    }

                    .project-progressbar {
                        width: 100px;
                        background-color: $dividerColor;
                        float: right;
                        margin-top: 12px;

                        .project-progressbar-value {
                            border-radius: $borderRadius;
                            background-color: $primaryColor;
                            height: 4px;
                        }
                    }
                }
            }
        }
    }
}

.widget-contacts {
    overflow: hidden;

    .p-panel {
        .p-panel-content {
            min-height: 350px;
            padding: 0px !important;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            border-bottom: 1px solid $dividerColor;
            padding: 10px 15px;
            width: 100%;
            text-decoration: none;
            position: relative;
            display: block;
            border-radius: $borderRadius;
            transition: background-color .2s;

            img {
                float: left;
                margin-right: 8px;
            }

            .contact-info {
                float: left;

                .name {
                    display: block;
                    margin-top: 4px;
                    font-size: 14px;
                }

                .location {
                    margin-top: 4px;
                    display: block;
                    font-size: 12px;
                    color: $textSecondaryColor;
                }
            }

            .contact-actions {
                float: right;
                padding-top: 12px;

                button:last-child {
                    margin-left: 6px;
                }
            }

            &:last-child {
                border: 0;
            }
        }
    }
}

.widget-timeline {
    margin: 0;

    > .grid {
        .col-3 {
            font-size: 14px;
            position: relative;
            border-right: 1px solid $dividerColor;

            i {
                font-size: 24px;
                position: absolute;
                right: -16px;
            }
        }

        .col-9 {
            padding-left: 1.5em;

            .event-text {
                color: $textSecondaryColor;
                font-size: 14px;
                display: block;
                padding-bottom: 20px;
            }

            .event-content {
                img {
                    width: 100%;
                }
            }
        }
    }
}

.widget-image-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $dividerColor;
        padding: 1rem;

        &:first-child {
            border-top: 0 none;
            font-weight: 600;
            font-size: 1rem;
        }

        img {
            width: 64px;
            vertical-align: middle;
            margin-right: .5rem;
            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
        }

        .listitem-value {
            font-weight: 600;
        }
    }
}

.widget-chat {
    ul {
        padding: 1rem;
        margin: 0;
        list-style-type: none;

        li {
            padding: .5rem 0;
            display: flex;
            align-items: flex-start;

            img {
                width: 36px;
            }

            div {
                padding: .5rem 1rem;
                margin: .25rem 0;
                border-radius: $borderRadius;
            }

            &.message-from {
                justify-content: flex-start;

                img {
                    margin-right: .5rem;
                }

                div {
                    order: 2;
                    background-color: #39a3f4;
                    color: #ffffff;
                    border: solid 1px #39a3f4;
                }
            }

            &.message-own {
                justify-content: flex-end;

                img {
                    margin-left: .5rem;
                    order: 2;
                }

                div {
                    order: 1;
                    background-color: #6ebc3b;
                    color: #ffffff;
                    border: solid 1px #6ebc3b;
                }
            }
        }
    }

    .new-message {
        height: 40px;
        border-top: 1px solid $dividerColor;
        color: #afafc0;

        .message-attachment {
            display: inline-block;
            border-right: 1px solid $dividerColor;
            width: 40px;
            line-height: 40px;
            height: 100%;
            text-align: center;

            i {
                line-height: inherit;
                font-size: 24px;
            }
        }

        .message-input {
            position: relative;
            top: -4px;
            width: calc(100% - 100px);
            display: inline-block;

            input {
                border: 0 none;
                font-size: 14px;
                width: 100%;
                background: transparent;
                outline: 0 none;
                padding-left: 8px;
            }
        }
    }
}

.widget-pricing-box {
    @include border-radius(3px);
    padding: 30px 0;
    text-align: center;
    background-color: #e1e6e9;
    @include transition(background-color $transitionDuration);

    .pricing-name {
        font-size: 24px;
    }

    .pricing-fee {
        @include border-radius(50px);
        background-color: $primaryColor;
        color: #ffffff;
        width: 100px;
        height: 100px;
        margin: 30px auto;
        font-size: 22px;
        line-height: 100px;
    }

    &:hover {
        background-color: $primaryColor;
        color: #ffffff;

        p {
            color: #ffffff;
        }

        .pricing-fee {
            background-color: #eaeeef;
            color: $primaryColor;
        }
    }
}
