/* Login */
.login-body {
	height: 100vh;
	font-size: 16px;
	margin: 0;

	.body-container {
		height: 7px;
		border-top: 7px solid $primaryColor;

		.grid {
			margin-right: 0;
		}
	}

	.right-side {
		width: 50%;
	}

	.left-side {
		height: 100%;
		padding-left: 100px;
		width: 50%;

		img {
			padding: 100px 0 100px 0;
			width: 60px;
		}

		h1 {
			font-size: 80px;
			color: $textColor;
		}

		p {
			color: $textColor;
			padding-right: 50px;
			margin-top: 0;
			margin-bottom: 40px;
		}
	}
}

.login-wrapper {
	width: 400px;
	margin: 0 auto;
	text-align: center;

	.login-container {
		box-sizing: border-box;
		padding-top: 50%;
		text-align: center;
		color: #4f575b;

		.title {
			font-size: 20px;
			color: $textColor;
			display: block;
			text-align: left;
			margin-bottom: 10px;
		}

		.password-container {
			text-align: right;
		}

		.p-link {
			color: $primaryColor;
			text-decoration: none;
			font-size: 16px;
			line-height: 32px;
			display: inline-block;
			padding-bottom: 2px;
			border-bottom: 1px solid transparent;
			transition: border-bottom-color $transitionDuration;

			&:hover {
				border-bottom-color: $primaryColor;
			}
		}

		input {
			width: 100%;
			font-size: 1em;
		}

		.p-button {
			width: 100%;
			font-size: 16px;
		}

	}
}

@media (max-width: 64em) {
	.login-body {
		text-align: center;
		background-size: cover;

		.left-side {
			height: 300px;
			padding-left: 25px;
			width: 100%;

			img {
				padding: 20px 0 20px 0;
			}

			p {
				display: none;
			}
		}

		.right-side {
			height: 100%;
			width: 100%;
		}
	}

	.login-wrapper {
		width: 320px;

		.login-container {
			padding-top: 25%;
		}
	}
}

@media (max-width: 40em) {
	.login-body {
		.left-side {
			height: 200px;
			padding-left: 25px;
			text-align: center;
			width: 100%;

			img {
				padding: 20px 0 20px 0;
			}

			h1 {
				font-size: 40px;
			}

			p {
				display: none;
			}
		}

		.right-side {
			height: calc(100% - 250px);
			width: 100%;
		}
	}

	.login-wrapper {
		width: 320px;

		.login-container {
			padding-top: 25%;
		}
	}
}
