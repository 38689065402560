/* Predefined Colors */
/* Add your variable customizations of layout here */
/* source-sans-pro-300 - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Light"), local("SourceSansPro-Light"), url("../fonts/source-sans-pro-v11-latin-ext_latin-300.eot#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-300.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-ext_latin-300.woff") format("woff"), url("../fonts/source-sans-pro-v11-latin-ext_latin-300.ttf") format("truetype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-300.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-regular - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.eot#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.woff") format("woff"), url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.ttf") format("truetype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-italic - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Italic"), local("SourceSansPro-Italic"), url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.eot#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.woff") format("woff"), url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.ttf") format("truetype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-700 - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("../fonts/source-sans-pro-v11-latin-ext_latin-700.eot#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-700.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-ext_latin-700.woff") format("woff"), url("../fonts/source-sans-pro-v11-latin-ext_latin-700.ttf") format("truetype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-700.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-300 - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Light"), local("SourceSansPro-Light"), url("../fonts/source-sans-pro-v11-latin-ext_latin-300.eot#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-300.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-ext_latin-300.woff") format("woff"), url("../fonts/source-sans-pro-v11-latin-ext_latin-300.ttf") format("truetype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-300.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-regular - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.eot#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.woff") format("woff"), url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.ttf") format("truetype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-italic - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Italic"), local("SourceSansPro-Italic"), url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.eot#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.woff") format("woff"), url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.ttf") format("truetype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-700 - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("../fonts/source-sans-pro-v11-latin-ext_latin-700.eot#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-700.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-ext_latin-700.woff") format("woff"), url("../fonts/source-sans-pro-v11-latin-ext_latin-700.ttf") format("truetype"), url("../fonts/source-sans-pro-v11-latin-ext_latin-700.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* Utils */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.card {
  background: #1e1e1e;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.card:last-child {
  margin: 0;
}

.nopad {
  padding: 0;
}
.nopad .p-panel-content {
  padding: 0;
}

.p-sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.separator {
  border: 1px solid #383838;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: normal;
  line-height: 1.2;
  color: inherit;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

mark {
  background: #FFF8E1;
  padding: 0.25rem 0.4rem;
  border-radius: 3px;
  font-family: monospace;
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90A4AE;
}

hr {
  border-top: solid #383838;
  border-width: 1px 0 0 0;
  margin: 1rem 0;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5;
}
p:last-child {
  margin-bottom: 0;
}

.loader {
  min-height: 100%;
}
.loader > div:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #333;
  transform-origin: 100% 100%;
}
.loader > div span {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  animation: 0.5s infinite linear;
}
.loader > div span:nth-child(1) {
  background: #6ebc3b;
  animation: kiri 1.2s infinite linear;
}
.loader > div span:nth-child(2) {
  background: #f6a821;
  z-index: 100;
}
.loader > div span:nth-child(3) {
  background: #39a3f4;
  animation: kanan 1.2s infinite linear;
}

@-webkit-keyframes kanan {
  0% {
    -webkit-transform: translateX(20px);
  }
  50% {
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(20px);
    z-index: 200;
  }
}
@-moz-keyframes kanan {
  0% {
    -moz-transform: translateX(20px);
  }
  50% {
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(20px);
    z-index: 200;
  }
}
@-o-keyframes kanan {
  0% {
    -o-transform: translateX(20px);
  }
  50% {
    -o-transform: translateX(-20px);
  }
  100% {
    -o-transform: translateX(20px);
    z-index: 200;
  }
}
@-webkit-keyframes kiri {
  0% {
    -webkit-transform: translateX(-20px);
    z-index: 200;
  }
  50% {
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(-20px);
  }
}
@-moz-keyframes kiri {
  0% {
    -moz-transform: translateX(-20px);
    z-index: 200;
  }
  50% {
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(-20px);
  }
}
@-o-keyframes kiri {
  0% {
    -o-transform: translateX(-20px);
    z-index: 200;
  }
  50% {
    -o-transform: translateX(20px);
  }
  100% {
    -o-transform: translateX(-20px);
  }
}
* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 14px;
}

/* Main Layout */
body {
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background-color: #121212;
  color: rgba(255, 255, 255, 0.87);
}
body .layout-mask {
  display: none;
}
body a {
  text-decoration: none;
  color: #39a3f4;
}

.layout-wrapper .topbar {
  background-color: #39a3f4;
  padding: 10px 16px;
  height: 50px;
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 102;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.layout-wrapper .topbar .menu-button {
  display: none;
  cursor: pointer;
  vertical-align: top;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  margin-top: -10px;
  margin-left: 30px;
  color: #ffffff;
  user-select: none;
  transition: background-color 0.2s;
}
.layout-wrapper .topbar .menu-button i {
  font-size: 28px;
  line-height: inherit;
}
.layout-wrapper .topbar .menu-button:hover {
  background-color: #69b9f7;
}
.layout-wrapper .topbar .topbar-themeswitcher {
  position: relative;
  float: right;
  top: 8px;
  right: 10px;
}
.layout-wrapper .topbar .topbar-themeswitcher .p-inputswitch .p-inputswitch-slider {
  background-color: #ffffff;
  border-color: #d8d8d8;
}
.layout-wrapper .topbar .topbar-themeswitcher .p-inputswitch .p-inputswitch-slider:before {
  background-color: #d8d8d8;
}
.layout-wrapper .topbar .topbar-themeswitcher .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #243447;
  border-color: #243447;
}
.layout-wrapper .topbar .topbar-themeswitcher .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #000000;
}
.layout-wrapper .topbar .topbar-search {
  padding: 0;
  position: relative;
  float: right;
  margin: 0 10px 0 0;
  top: 3px;
}
.layout-wrapper .topbar .topbar-search input {
  display: inline-block;
  border: 0 none;
  font-size: 14px;
  background: #69b9f7;
  outline: 0 none;
  color: #ffffff;
  width: 175px;
  padding: 4px 20px 4px 8px;
  margin: 0px;
  border-radius: 3px;
}
.layout-wrapper .topbar .topbar-search input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.layout-wrapper .topbar .topbar-search input:-moz-placeholder {
  color: #ffffff;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.layout-wrapper .topbar .topbar-search input::-moz-placeholder {
  color: #ffffff;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.layout-wrapper .topbar .topbar-search input:-ms-input-placeholder {
  color: #ffffff;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.layout-wrapper .topbar .topbar-search .pi {
  font-size: 16px;
  position: absolute;
  top: 4px;
  right: 5px;
  color: #ffffff;
}
.layout-wrapper .topbar .logo-link {
  display: inline-block;
  height: 100%;
  width: 185px;
}
.layout-wrapper .topbar .logo-link .logo {
  height: 30px;
}
.layout-wrapper .topbar .profile {
  float: right;
  text-align: right;
  margin-top: -5px;
  font-weight: 700;
  cursor: pointer;
}
.layout-wrapper .topbar .profile img {
  vertical-align: middle;
  width: 40px;
  margin-right: 8px;
}
.layout-wrapper .topbar .profile .username {
  vertical-align: middle;
  margin-right: 8px;
  color: #ffffff;
}
.layout-wrapper .topbar .profile .pi {
  font-size: 16px;
  vertical-align: middle;
  color: #ffffff;
}
.layout-wrapper .topbar .topbar-menu {
  display: none;
  position: absolute;
  cursor: pointer;
  right: 30px;
  top: 50px;
  width: 250px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #39a3f4;
  border-top: 1px solid #0d8ced;
  animation-duration: 0.2s;
}
.layout-wrapper .topbar .topbar-menu.topbar-menu-visible {
  display: block;
}
.layout-wrapper .topbar .topbar-menu > li button {
  width: 100%;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-size: 14px;
  color: #ffffff;
  padding: 10px 16px;
  display: block;
}
.layout-wrapper .topbar .topbar-menu > li button i {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
}
.layout-wrapper .topbar .topbar-menu > li button span {
  margin-left: 6px;
  display: inline-block;
  vertical-align: middle;
}
.layout-wrapper .topbar .topbar-menu > li button img {
  display: inline-block;
  vertical-align: middle;
}
.layout-wrapper .topbar .topbar-menu > li button .topbar-badge {
  float: right;
  background-color: #ffffff;
  display: block;
  color: #39a3f4;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 1px;
  border-radius: 50%;
}
.layout-wrapper .topbar .topbar-menu > li button:hover {
  background-color: #69b9f7;
}
.layout-wrapper .topbar .topbar-menu > li ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.layout-wrapper .topbar .topbar-menu > li ul button {
  padding-left: 32px;
}
.layout-wrapper .topbar .topbar-menu > li ul.layout-submenu-container-enter {
  max-height: 0;
}
.layout-wrapper .topbar .topbar-menu > li ul.layout-submenu-container-enter-active {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}
.layout-wrapper .topbar .topbar-menu > li ul.layout-submenu-container-enter-done {
  transform: none;
}
.layout-wrapper .topbar .topbar-menu > li ul.layout-submenu-container-exit {
  max-height: 1000px;
}
.layout-wrapper .topbar .topbar-menu > li ul.layout-submenu-container-exit-active {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
}
.layout-wrapper .topbar .topbar-menu > li.topbar-search-item {
  border-top: 1px solid #0d8ced;
  padding: 10px 16px;
  display: none;
}
.layout-wrapper .topbar .topbar-menu > li.topbar-search-item .topbar-search {
  float: none;
  display: block;
  width: 100%;
  top: 0;
}
.layout-wrapper .topbar .topbar-menu > li.topbar-search-item .topbar-search input {
  width: 100%;
}
.layout-wrapper.layout-overlay .topbar, .layout-wrapper.layout-static .topbar {
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.layout-wrapper.layout-overlay .topbar .menu-button, .layout-wrapper.layout-static .topbar .menu-button {
  display: inline-block;
}
.layout-wrapper.layout-slim .topbar {
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
}

.layout-wrapper .layout-menu-container {
  background-color: #1e1e1e;
  position: fixed;
  z-index: 101;
  top: 50px;
  left: 0;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.layout-wrapper .layout-menu-container .layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.layout-wrapper .layout-menu-container .layout-menu li > a {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.87);
  display: block;
  user-select: none;
}
.layout-wrapper .layout-menu-container .layout-menu li > a span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
}
.layout-wrapper .layout-menu-container .layout-menu li > a i {
  display: inline-block;
  vertical-align: middle;
}
.layout-wrapper .layout-menu-container .layout-menu li > a > span.menuitem-badge {
  background-color: #39a3f4;
  width: 18px;
  height: 18px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  float: right;
  position: relative;
  top: 2px;
  padding-top: 1px;
  border-radius: 50%;
}
.layout-wrapper .layout-menu-container .layout-menu li > a.rotated-icon .layout-menuitem-icon {
  transform: rotate(90deg);
}
.layout-wrapper .layout-menu-container .layout-menu li > a .layout-menuitem-toggler {
  transition: transform 0.2s;
}
.layout-wrapper .layout-menu-container .layout-menu li > a.router-link-active {
  color: #39a3f4;
  font-weight: 700;
}
.layout-wrapper .layout-menu-container .layout-menu li > a.router-link-active, .layout-wrapper .layout-menu-container .layout-menu li > a.router-link-exact-active {
  color: #39a3f4;
}
.layout-wrapper .layout-menu-container .layout-menu li > a.active-menuitem-routerlink {
  color: #39a3f4;
  font-weight: 700;
}
.layout-wrapper .layout-menu-container .layout-menu li > a:not(:hover).blue-theme i {
  color: #39a3f4;
}
.layout-wrapper .layout-menu-container .layout-menu li > a:not(:hover).green-theme i {
  color: #6ebc3b;
}
.layout-wrapper .layout-menu-container .layout-menu li > a:not(:hover).cyan-theme i {
  color: #26C6DA;
}
.layout-wrapper .layout-menu-container .layout-menu li > a:not(:hover).purple-theme i {
  color: #7E57C2;
}
.layout-wrapper .layout-menu-container .layout-menu li > a:not(:hover).indigo-theme i {
  color: #5C6BC0;
}
.layout-wrapper .layout-menu-container .layout-menu li > a:not(:hover).yellow-theme i {
  color: #ffc800;
}
.layout-wrapper .layout-menu-container .layout-menu li > a:not(:hover).orange-theme i {
  color: #f6a821;
}
.layout-wrapper .layout-menu-container .layout-menu li > a:not(:hover).pink-theme i {
  color: #EC407A;
}
.layout-wrapper .layout-menu-container .layout-menu li > a.rotated-icon .layout-menuitem-icon {
  transform: rotate(90deg);
}
.layout-wrapper .layout-menu-container .layout-menu li ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
}
.layout-wrapper .layout-menu-container .layout-menu li ul.layout-submenu-container-enter {
  max-height: 0;
}
.layout-wrapper .layout-menu-container .layout-menu li ul.layout-submenu-container-enter-active {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}
.layout-wrapper .layout-menu-container .layout-menu li ul.layout-submenu-container-enter-done {
  transform: none;
}
.layout-wrapper .layout-menu-container .layout-menu li ul.layout-submenu-container-exit {
  max-height: 1000px;
}
.layout-wrapper .layout-menu-container .layout-menu li ul.layout-submenu-container-exit-active {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
}
.layout-wrapper .layout-menu-container .layout-menu li.layout-root-menuitem > .layout-menuitem-root-text {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  padding: 1.5rem 0 0.5rem 1.5rem;
  color: rgba(255, 255, 255, 0.87);
}
.layout-wrapper .layout-menu-container .layout-menu li.layout-root-menuitem > a {
  display: none !important;
}
.layout-wrapper .layout-menu-container .layout-menu li.active-menuitem > a .layout-menuitem-toggler {
  transform: rotate(-180deg);
}
.layout-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip {
  display: none;
  padding: 0 5px;
  position: absolute;
  left: 61px;
  top: 7px;
  line-height: 1;
}
.layout-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-text {
  padding: 6px 8px;
  font-weight: 700;
  background-color: #39a3f4;
  color: #ffffff;
  min-width: 75px;
  white-space: nowrap;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.layout-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #39a3f4;
}

.layout-wrapper .layout-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.layout-wrapper .layout-content .layout-breadcrumb {
  background-color: #1e1e1e;
  border-bottom: 1px solid #383838;
  min-height: 42px;
  padding: 0px 20px;
}
.layout-wrapper .layout-content .layout-breadcrumb:before, .layout-wrapper .layout-content .layout-breadcrumb:after {
  content: "";
  display: table;
}
.layout-wrapper .layout-content .layout-breadcrumb:after {
  clear: both;
}
.layout-wrapper .layout-content .layout-breadcrumb ul {
  margin: 10px 0 0 0;
  padding: 0;
  list-style: none;
  color: rgba(255, 255, 255, 0.87);
  display: inline-block;
}
.layout-wrapper .layout-content .layout-breadcrumb ul li {
  display: inline-block;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.87);
  text-transform: capitalize;
  padding: 0 2px;
}
.layout-wrapper .layout-content .layout-breadcrumb ul li button {
  font-family: "Source Sans Pro", Arial, sans-serif;
  color: rgba(255, 255, 255, 0.87);
}
.layout-wrapper .layout-content .layout-breadcrumb ul li button i {
  font-size: 20px;
}
.layout-wrapper .layout-content .layout-breadcrumb .layout-breadcrumb-options {
  float: right;
  padding: 0;
  height: 100%;
  cursor: pointer;
}
.layout-wrapper .layout-content .layout-breadcrumb .layout-breadcrumb-options button {
  font-family: "Source Sans Pro", Arial, sans-serif;
  color: rgba(255, 255, 255, 0.6);
  display: inline-block;
  width: 42px;
  height: 42px;
  font-size: 20px;
  line-height: 42px;
  text-align: center;
  border-radius: 0;
}
.layout-wrapper .layout-content .layout-breadcrumb .layout-breadcrumb-options button:hover {
  background-color: #39a3f4;
  color: #ffffff;
}
.layout-wrapper .layout-content .layout-breadcrumb .layout-breadcrumb-options button i {
  font-size: 18px;
  line-height: inherit;
}
.layout-wrapper .layout-content .layout-content-container {
  padding: 20px 20px;
  flex: 1 1 0;
}

.layout-wrapper .layout-footer {
  font-weight: 700;
  background-color: #1e1e1e;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.layout-wrapper .layout-footer .footer-text-left {
  float: left;
}
.layout-wrapper .layout-footer .footer-text-right {
  float: right;
}

@media (min-width: 1025px) {
  .layout-wrapper.layout-horizontal .layout-content {
    padding-top: 100px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container {
    padding: 0 20px;
    width: 100%;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu-title {
    display: none;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li {
    display: inline-block;
    position: relative;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.layout-root-menuitem > .layout-menuitem-root-text {
    display: none !important;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.layout-root-menuitem > a {
    display: block !important;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > a {
    padding: 12px;
    height: 50px;
    margin-bottom: -4px;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.2s, background-color 0.2s;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > a:not(.p-disabled):hover {
    border-bottom-color: #39a3f4;
    color: #39a3f4;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > a {
    background-color: #39a3f4;
    color: #ffffff;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > a:not(.p-disabled):hover {
    color: #ffffff;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > ul {
    top: 50px;
    left: 0;
    position: absolute;
    display: block;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li ul {
    min-width: 200px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul {
    display: none;
    margin: 0;
    padding: 0;
    max-height: none;
    list-style-type: none;
    background-color: #1e1e1e;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li a {
    padding: 8px 12px;
    display: block;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li a span {
    margin-left: 6px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li a i.layout-menuitem-toggler {
    float: right;
    display: block;
    margin-top: 4px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li a:not(.p-disabled):hover {
    background-color: #39a3f4;
    color: #ffffff;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li.p-menu-separator {
    border-top: 1px solid #383838;
    margin: 0.25rem 0;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a {
    color: #39a3f4;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a:not(.p-disabled):hover {
    color: #ffffff;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li.active-menuitem > ul {
    display: block;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li ul {
    width: 100%;
    box-shadow: none;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li ul li a {
    padding-left: 24px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li ul li ul li a {
    padding-left: 36px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li a {
    padding-left: 48px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li a {
    padding-left: 60px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li ul li a {
    padding-left: 72px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu li > a span.menuitem-badge {
    float: none;
    top: 0px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu-footer {
    display: none;
  }
}
@media (min-width: 1025px) {
  .layout-wrapper.layout-overlay .layout-menu-container, .layout-wrapper.layout-static .layout-menu-container {
    padding: 0;
    width: 230px;
    height: calc(100% - 50px);
    overflow: auto;
    transition: transform 0.2s;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu-content, .layout-wrapper.layout-static .layout-menu-container .layout-menu-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .layout-wrapper.layout-overlay .layout-menu-container.layout-menu-container-inactive, .layout-wrapper.layout-static .layout-menu-container.layout-menu-container-inactive {
    transform: translateX(-100%);
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu-title, .layout-wrapper.layout-static .layout-menu-container .layout-menu-title {
    display: block;
    padding: 11px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid #383838;
    letter-spacing: 2px;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu, .layout-wrapper.layout-static .layout-menu-container .layout-menu {
    flex: 1 1 0;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu li a, .layout-wrapper.layout-static .layout-menu-container .layout-menu li a {
    padding: 10px 12px;
    display: block;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu li a:not(.p-disabled):hover, .layout-wrapper.layout-static .layout-menu-container .layout-menu li a:not(.p-disabled):hover {
    background-color: #39a3f4;
    color: #ffffff;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu li a i:first-child, .layout-wrapper.layout-static .layout-menu-container .layout-menu li a i:first-child {
    font-size: 14px;
    vertical-align: middle;
    display: inline-block;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu li a i.layout-menuitem-toggler, .layout-wrapper.layout-static .layout-menu-container .layout-menu li a i.layout-menuitem-toggler {
    float: right;
    line-height: 22px;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu li a > span, .layout-wrapper.layout-static .layout-menu-container .layout-menu li a > span {
    vertical-align: middle;
    display: inline-block;
    margin-left: 6px;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu li.p-menu-separator, .layout-wrapper.layout-static .layout-menu-container .layout-menu li.p-menu-separator {
    border-top: 1px solid #383838;
    margin: 0.25rem 0;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu li.active-menuitem > a, .layout-wrapper.layout-static .layout-menu-container .layout-menu li.active-menuitem > a {
    background-color: #1e1e1e;
    color: #39a3f4;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu li.active-menuitem > a:hover, .layout-wrapper.layout-static .layout-menu-container .layout-menu li.active-menuitem > a:hover {
    background-color: #39a3f4;
    color: #ffffff;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu > li:first-child, .layout-wrapper.layout-static .layout-menu-container .layout-menu > li:first-child {
    margin-top: 8px;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu > li ul, .layout-wrapper.layout-static .layout-menu-container .layout-menu > li ul {
    background: #1e1e1e;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu > li ul li a, .layout-wrapper.layout-static .layout-menu-container .layout-menu > li ul li a {
    padding-left: 24px;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu > li ul li ul li a, .layout-wrapper.layout-static .layout-menu-container .layout-menu > li ul li ul li a {
    padding-left: 36px;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu > li ul li ul li ul li a, .layout-wrapper.layout-static .layout-menu-container .layout-menu > li ul li ul li ul li a {
    padding-left: 48px;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu > li ul li ul li ul li ul li a, .layout-wrapper.layout-static .layout-menu-container .layout-menu > li ul li ul li ul li ul li a {
    padding-left: 60px;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu > li ul li ul li ul li ul li ul li a, .layout-wrapper.layout-static .layout-menu-container .layout-menu > li ul li ul li ul li ul li ul li a {
    padding-left: 72px;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu-footer, .layout-wrapper.layout-static .layout-menu-container .layout-menu-footer {
    padding-bottom: 10px;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu-footer .layout-menu-footer-title, .layout-wrapper.layout-static .layout-menu-container .layout-menu-footer .layout-menu-footer-title {
    display: block;
    padding: 11px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid #383838;
    letter-spacing: 2px;
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu-footer .layout-menu-footer-content, .layout-wrapper.layout-static .layout-menu-container .layout-menu-footer .layout-menu-footer-content {
    padding: 10px 12px;
    color: rgba(255, 255, 255, 0.87);
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu-footer .layout-menu-footer-content .p-progressbar, .layout-wrapper.layout-static .layout-menu-container .layout-menu-footer .layout-menu-footer-content .p-progressbar {
    height: 5px;
    border: 0 none;
    margin: 16px 0 4px 0;
  }
  .layout-wrapper.layout-overlay .layout-content {
    padding-top: 50px;
  }
  .layout-wrapper.layout-static .layout-content {
    padding-top: 50px;
    margin-left: 230px;
    transition: margin-left 0.2s;
  }
  .layout-wrapper.layout-static.layout-static-inactive .layout-menu-container {
    transform: translateX(-100%);
  }
  .layout-wrapper.layout-static.layout-static-inactive .layout-content {
    margin-left: 0;
  }
}
@media (min-width: 1025px) {
  .layout-wrapper.layout-slim .layout-content {
    padding-top: 50px;
    margin-left: 60px;
  }
  .layout-wrapper.layout-slim .layout-menu-container {
    padding: 0;
    height: 100%;
    width: 60px;
    overflow: visible;
    top: 50px;
    position: fixed;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu-title, .layout-wrapper.layout-slim .layout-menu-container .layout-menu-footer {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu {
    padding: 8px 0 0 0;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li {
    position: relative;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.layout-root-menuitem > .layout-menuitem-root-text {
    display: none !important;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.layout-root-menuitem > a {
    display: block !important;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > a {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > a i:first-child {
    font-size: 19px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > a span, .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > a .layout-menuitem-toggler {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > a:hover + .layout-menu-tooltip {
    display: block;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > a:not(.p-disabled):hover {
    background-color: #39a3f4;
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.p-menu-separator {
    border-top: 1px solid #383838;
    margin: 0.25rem 0;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul {
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
    background-color: #1e1e1e;
    position: absolute;
    top: 0;
    left: 60px;
    min-width: 200px;
    max-height: none;
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li a {
    padding: 8px 12px;
    display: block;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li a span {
    margin-left: 6px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li a i.layout-menuitem-toggler {
    float: right;
    display: block;
    margin-top: 4px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li a:not(.p-disabled):hover {
    background-color: #39a3f4;
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li.p-menu-separator {
    border-top: 1px solid #383838;
    margin: 0.25rem 0;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #39a3f4;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li.active-menuitem > a:hover {
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li.active-menuitem > ul {
    display: block;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul {
    width: 100%;
    box-shadow: none;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li a {
    padding-left: 24px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li ul li a {
    padding-left: 36px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li ul li ul li a {
    padding-left: 48px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li ul li ul li ul li a {
    padding-left: 60px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li ul li ul li ul li ul li a {
    padding-left: 72px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > a {
    background-color: #1e1e1e;
    color: #39a3f4;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > a:hover {
    background-color: #39a3f4;
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > a:hover + .layout-menu-tooltip {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > ul {
    display: block;
  }
}
@media (max-width: 1024px) {
  .layout-wrapper .topbar {
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
  }
  .layout-wrapper .topbar .profile .username {
    display: none;
  }
  .layout-wrapper .topbar .menu-button {
    display: inline-block;
    margin-left: 10px;
  }
  .layout-wrapper .topbar .topbar-search {
    display: none;
  }
  .layout-wrapper .topbar .topbar-menu > li.topbar-search-item {
    display: block;
  }
  .layout-wrapper .topbar .logo-link {
    width: auto;
  }
  .layout-wrapper .layout-mask {
    display: none;
  }
  .layout-wrapper .layout-menu-container {
    padding: 0;
    width: 230px;
    height: 100%;
    overflow: auto;
    transform: translateX(-100%);
    transition: transform 0.2s;
  }
  .layout-wrapper .layout-menu-container.layout-menu-container-inactive {
    transform: translateX(-100%) !important;
  }
  .layout-wrapper .layout-menu-container .layout-menu-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .layout-wrapper .layout-menu-container .layout-menu-title {
    display: block;
    padding: 16px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid #383838;
    letter-spacing: 2px;
  }
  .layout-wrapper .layout-menu-container .layout-menu {
    flex: 1 1 0;
  }
  .layout-wrapper .layout-menu-container .layout-menu li a {
    padding: 10px 12px;
    display: block;
  }
  .layout-wrapper .layout-menu-container .layout-menu li a:hover {
    background-color: #39a3f4;
    color: #ffffff;
  }
  .layout-wrapper .layout-menu-container .layout-menu li a i:first-child {
    font-size: 18px;
    vertical-align: middle;
    display: inline-block;
  }
  .layout-wrapper .layout-menu-container .layout-menu li a i.layout-menuitem-toggler {
    float: right;
    line-height: 22px;
  }
  .layout-wrapper .layout-menu-container .layout-menu li a > span {
    vertical-align: middle;
    display: inline-block;
    line-height: 15px;
    margin-left: 6px;
  }
  .layout-wrapper .layout-menu-container .layout-menu li.layout-root-menuitem > .layout-menuitem-root-text {
    display: block !important;
  }
  .layout-wrapper .layout-menu-container .layout-menu li.layout-root-menuitem > a {
    display: none !important;
  }
  .layout-wrapper .layout-menu-container .layout-menu li.active-menuitem > a {
    background-color: #1e1e1e;
    color: #39a3f4;
  }
  .layout-wrapper .layout-menu-container .layout-menu li.active-menuitem > a:hover {
    background-color: #39a3f4;
    color: #ffffff;
  }
  .layout-wrapper .layout-menu-container .layout-menu > li:first-child {
    margin-top: 8px;
  }
  .layout-wrapper .layout-menu-container .layout-menu > li ul {
    background: #1e1e1e;
  }
  .layout-wrapper .layout-menu-container .layout-menu > li ul li a {
    padding-left: 24px;
  }
  .layout-wrapper .layout-menu-container .layout-menu > li ul li ul li a {
    padding-left: 36px;
  }
  .layout-wrapper .layout-menu-container .layout-menu > li ul li ul li ul li a {
    padding-left: 48px;
  }
  .layout-wrapper .layout-menu-container .layout-menu > li ul li ul li ul li ul li a {
    padding-left: 60px;
  }
  .layout-wrapper .layout-menu-container .layout-menu > li ul li ul li ul li ul li ul li a {
    padding-left: 72px;
  }
  .layout-wrapper .layout-menu-container .layout-menu-footer {
    padding-bottom: 50px;
  }
  .layout-wrapper .layout-menu-container .layout-menu-footer .layout-menu-footer-title {
    display: block;
    padding: 16px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid #383838;
    letter-spacing: 2px;
  }
  .layout-wrapper .layout-menu-container .layout-menu-footer .layout-menu-footer-content {
    padding: 10px 12px;
    color: rgba(255, 255, 255, 0.87);
  }
  .layout-wrapper .layout-menu-container .layout-menu-footer .layout-menu-footer-content .p-progressbar {
    height: 5px;
    border: 0 none;
    margin: 16px 0 4px 0;
  }
  .layout-wrapper.layout-mobile-active .layout-menu-container {
    transform: translateX(0);
  }
  .layout-wrapper.layout-mobile-active .layout-mask {
    display: block;
    position: fixed;
    background-color: #121212;
    opacity: 0.7;
    filter: alpha(opacity=70);
    height: 100%;
    width: 100%;
    top: 50px;
    left: 0;
    z-index: 100;
  }
  .layout-wrapper .layout-content {
    padding: 50px 0px 0px 0px;
  }
}
.layout-config {
  position: fixed;
  top: 50px;
  padding: 0;
  right: 0;
  display: block;
  width: 16em;
  z-index: 998;
  height: calc(100% - 50px);
  transform: translate3d(16em, 0px, 0px);
  transition: transform 0.2s;
  backface-visibility: hidden;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
  color: rgba(255, 255, 255, 0.87);
  background-color: #1e1e1e;
}
.layout-config.layout-config-active {
  transform: translate3d(0px, 0px, 0px);
}
.layout-config.layout-config-active .layout-config-content .layout-config-button i {
  transform: rotate(360deg);
}
.layout-config .layout-config-content {
  position: relative;
  height: 100%;
  padding: 1em;
}
.layout-config .layout-config-content .layout-config-button {
  display: block;
  position: absolute;
  width: 52px;
  height: 52px;
  line-height: 52px;
  background-color: #39a3f4;
  text-align: center;
  color: #ffffff;
  top: 230px;
  left: -52px;
  z-index: -1;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.2s;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
}
.layout-config .layout-config-content .layout-config-button i {
  font-size: 32px;
  line-height: inherit;
  cursor: pointer;
  transform: rotate(0deg);
  transition: color 0.2s, transform 1s;
}
.layout-config .layout-config-content .layout-config-close {
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  right: 14px;
  top: 10px;
  background-color: #e54a51;
  border-radius: 50%;
  transition: background-color 0.2s;
}
.layout-config .layout-config-content .layout-config-close i {
  color: #ffffff;
  line-height: inherit;
  font-size: 12px;
}
.layout-config .layout-config-content .layout-config-close:hover {
  background-color: #ea6e73;
}
.layout-config .layout-config-content .layout-themes {
  display: flex;
  flex-wrap: wrap;
}
.layout-config .layout-config-content .layout-themes > div {
  padding: 0.25rem;
}
.layout-config .layout-config-content .layout-themes button {
  width: 2rem;
  height: 2rem;
  border-radius: 3px;
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.layout-config .layout-config-content .layout-themes button i {
  font-size: 1rem;
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 50%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}
.layout-config .layout-config-content .layout-themes button:hover {
  transform: scale(1.1);
}
.layout-config .p-col {
  text-align: center;
}
.layout-config p {
  line-height: 1.5;
  margin-top: 0;
  color: rgba(255, 255, 255, 0.6);
}

.blocked-scroll-config {
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .layout-config {
    transform: translate3d(100%, 0px, 0px);
  }
  .layout-config.layout-config-active {
    transform: translate3d(0px, 0px, 0px);
  }
  .layout-config .layout-config-button {
    left: auto;
    right: -52px;
  }
  .layout-config .layout-config-close {
    right: 10px;
  }
}
.widget-overview-box {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 15px;
  position: relative;
  min-height: 100px;
  overflow: hidden;
}
.widget-overview-box h1 {
  font-size: 12px;
  color: #ffffff;
  margin: 0;
}
.widget-overview-box img {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.widget-overview-box .overview-value {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}
.widget-overview-box .overview-ratio {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #ffffff;
}
.widget-overview-box .overview-ratio .overview-direction {
  display: inline-block;
  text-align: center;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
}
.widget-overview-box .overview-ratio .overview-direction i {
  vertical-align: middle;
  font-size: inherit;
}
.widget-overview-box .overview-ratio .overview-ratio-value {
  display: inline-block;
  height: 20px;
  padding: 0 4px;
}
.widget-overview-box.overview-box-1 {
  background-image: linear-gradient(to bottom, #39a3f4 1%, #279cf5 99%);
}
.widget-overview-box.overview-box-1 .overview-direction {
  color: #39a3f4;
}
.widget-overview-box.overview-box-1 .overview-ratio-value {
  background-color: #1164a3;
  color: #39a3f4;
}
.widget-overview-box.overview-box-2 {
  background-image: linear-gradient(to bottom, #76be45, #66b92d);
}
.widget-overview-box.overview-box-2 .overview-direction {
  color: #76be45;
}
.widget-overview-box.overview-box-2 .overview-ratio-value {
  background-color: #447d1d;
  color: #76be45;
}
.widget-overview-box.overview-box-3 {
  background-image: linear-gradient(to bottom, #dea242, #d5942e);
}
.widget-overview-box.overview-box-3 .overview-direction {
  color: #dea242;
}
.widget-overview-box.overview-box-3 .overview-ratio-value {
  background-color: #90631d;
  color: #dea242;
}
.widget-overview-box.overview-box-4 {
  background-image: linear-gradient(to bottom, #d67161, #d65c49);
}
.widget-overview-box.overview-box-4 .overview-direction {
  color: #d67161;
}
.widget-overview-box.overview-box-4 .overview-ratio-value {
  background-color: #943728;
  color: #d67161;
}

.widget-circle-panel.card {
  padding: 1rem 0 0 0;
}
.widget-circle-panel h4 {
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #383838;
}
.widget-circle-panel .p-grid > div {
  padding: 2em 0;
}
.widget-circle-panel div.p-col-12:not(:last-child) {
  border-right: 1px solid #383838;
}
.widget-circle-panel .status-title {
  margin-bottom: 0.714em;
  font-size: 1.429em;
  text-align: center;
}
.widget-circle-panel .circle1 {
  width: 10.714em;
  height: 10.714em;
  border: 10px solid #383838;
  border-right-color: #6ebc3b;
  border-top-color: #6ebc3b;
  border-bottom-color: #6ebc3b;
  border-left-color: #6ebc3b;
  border-radius: 50%;
  margin: auto;
  border-left-color: #383838;
}
.widget-circle-panel .circle1 i {
  position: relative;
  font-size: 24px;
  left: 50%;
  top: 20%;
  width: 36px;
  height: 36px;
  margin-left: -20px;
  color: #6ebc3b;
  text-align: center;
}
.widget-circle-panel .circle1 span {
  width: 79px;
  height: 50px;
  position: relative;
  vertical-align: middle;
  text-align: center;
  top: 40%;
  left: 50%;
  margin-left: -35px;
  color: #6ebc3b;
  font-weight: bold;
  font-size: 30px;
}
.widget-circle-panel .circle2 {
  width: 10.714em;
  height: 10.714em;
  border: 10px solid #383838;
  border-right-color: #f6a821;
  border-top-color: #f6a821;
  border-bottom-color: #f6a821;
  border-left-color: #f6a821;
  border-radius: 50%;
  margin: auto;
  border-bottom-color: #383838;
  border-left-color: #383838;
  border-right-color: #383838;
}
.widget-circle-panel .circle2 i {
  position: relative;
  font-size: 24px;
  left: 50%;
  top: 20%;
  width: 36px;
  height: 36px;
  margin-left: -20px;
  color: #f6a821;
  text-align: center;
}
.widget-circle-panel .circle2 span {
  width: 79px;
  height: 50px;
  position: relative;
  vertical-align: middle;
  text-align: center;
  top: 40%;
  left: 50%;
  margin-left: -35px;
  color: #f6a821;
  font-weight: bold;
  font-size: 30px;
}
.widget-circle-panel .circle3 {
  width: 10.714em;
  height: 10.714em;
  border: 10px solid #383838;
  border-right-color: #039ade;
  border-top-color: #039ade;
  border-bottom-color: #039ade;
  border-left-color: #039ade;
  border-radius: 50%;
  margin: auto;
  border-bottom-color: #383838;
  border-right-color: #383838;
}
.widget-circle-panel .circle3 i {
  position: relative;
  font-size: 24px;
  left: 50%;
  top: 20%;
  width: 36px;
  height: 36px;
  margin-left: -20px;
  color: #039ade;
  text-align: center;
}
.widget-circle-panel .circle3 span {
  width: 79px;
  height: 50px;
  position: relative;
  vertical-align: middle;
  text-align: center;
  top: 40%;
  left: 50%;
  margin-left: -35px;
  color: #039ade;
  font-weight: bold;
  font-size: 30px;
}
.widget-circle-panel .circle4 {
  border-left-color: #383838;
  width: 10.714em;
  height: 10.714em;
  border: 10px solid #383838;
  border-right-color: #d66351;
  border-top-color: #d66351;
  border-bottom-color: #d66351;
  border-left-color: #d66351;
  border-radius: 50%;
  margin: auto;
}
.widget-circle-panel .circle4 i {
  position: relative;
  font-size: 24px;
  left: 50%;
  top: 20%;
  width: 36px;
  height: 36px;
  margin-left: -20px;
  color: #d66351;
  text-align: center;
}
.widget-circle-panel .circle4 span {
  width: 79px;
  height: 50px;
  position: relative;
  vertical-align: middle;
  text-align: center;
  top: 40%;
  left: 50%;
  margin-left: -35px;
  color: #d66351;
  font-weight: bold;
  font-size: 30px;
}

.widget-user-card {
  border: 1px solid #383838;
  padding: 0;
}
.widget-user-card .user-card-header {
  height: 100px;
  overflow: hidden;
  position: relative;
}
.widget-user-card .user-card-header img {
  width: 100%;
}
.widget-user-card .user-card-header .p-button {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 32px;
  right: 24px;
}
.widget-user-card .user-card-content {
  min-height: 328px;
  background-color: transparent;
  position: relative;
}
.widget-user-card .user-card-content img {
  position: absolute;
  top: -90px;
  left: 24px;
}
.widget-user-card .user-card-content .user-card-name {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.87);
  position: absolute;
  top: -60px;
  margin-left: 110px;
  font-weight: 700;
  white-space: nowrap;
}
.widget-user-card .user-card-content .user-detail {
  text-align: left;
}
.widget-user-card .user-card-content .user-detail ul {
  padding: 0px 0 32px 0;
  margin: 0;
  list-style-type: none;
}
.widget-user-card .user-card-content .user-detail ul li {
  padding: 16px 24px;
  border-top: 1px solid #383838;
}
.widget-user-card .user-card-content .user-detail ul li:last-child {
  border-bottom: 1px solid #383838;
}
.widget-user-card .user-card-content .user-detail ul li i {
  font-size: 24px;
  margin-right: 8px;
  width: 32px;
  vertical-align: middle;
}
.widget-user-card .user-card-content .user-detail ul li .project-title {
  font-weight: 700;
  margin-right: 8px;
}
.widget-user-card .user-card-content .user-detail ul li .project-detail {
  color: rgba(255, 255, 255, 0.6);
}
.widget-user-card .user-card-content .user-detail ul li .project-progressbar {
  width: 100px;
  background-color: #383838;
  float: right;
  margin-top: 12px;
}
.widget-user-card .user-card-content .user-detail ul li .project-progressbar .project-progressbar-value {
  border-radius: 3px;
  background-color: #39a3f4;
  height: 4px;
}

.widget-contacts {
  overflow: hidden;
}
.widget-contacts .p-panel .p-panel-content {
  min-height: 350px;
  padding: 0px !important;
}
.widget-contacts ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.widget-contacts ul li {
  border-bottom: 1px solid #383838;
  padding: 10px 15px;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: block;
  border-radius: 3px;
  transition: background-color 0.2s;
}
.widget-contacts ul li img {
  float: left;
  margin-right: 8px;
}
.widget-contacts ul li .contact-info {
  float: left;
}
.widget-contacts ul li .contact-info .name {
  display: block;
  margin-top: 4px;
  font-size: 14px;
}
.widget-contacts ul li .contact-info .location {
  margin-top: 4px;
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}
.widget-contacts ul li .contact-actions {
  float: right;
  padding-top: 12px;
}
.widget-contacts ul li .contact-actions button:last-child {
  margin-left: 6px;
}
.widget-contacts ul li:last-child {
  border: 0;
}

.widget-timeline {
  margin: 0;
}
.widget-timeline > .p-grid .p-col-3 {
  font-size: 14px;
  position: relative;
  border-right: 1px solid #383838;
}
.widget-timeline > .p-grid .p-col-3 i {
  font-size: 24px;
  position: absolute;
  right: -16px;
}
.widget-timeline > .p-grid .p-col-9 {
  padding-left: 1.5em;
}
.widget-timeline > .p-grid .p-col-9 .event-text {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  display: block;
  padding-bottom: 20px;
}
.widget-timeline > .p-grid .p-col-9 .event-content img {
  width: 100%;
}

.widget-image-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget-image-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #383838;
  padding: 1rem;
}
.widget-image-list li:first-child {
  border-top: 0 none;
  font-weight: 600;
  font-size: 1rem;
}
.widget-image-list li img {
  width: 64px;
  vertical-align: middle;
  margin-right: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.widget-image-list li .listitem-value {
  font-weight: 600;
}

.widget-chat ul {
  padding: 1rem;
  margin: 0;
  list-style-type: none;
}
.widget-chat ul li {
  padding: 0.5rem 0;
  display: flex;
  align-items: flex-start;
}
.widget-chat ul li img {
  width: 36px;
}
.widget-chat ul li div {
  padding: 0.5rem 1rem;
  margin: 0.25rem 0;
  border-radius: 3px;
}
.widget-chat ul li.message-from {
  justify-content: flex-start;
}
.widget-chat ul li.message-from img {
  margin-right: 0.5rem;
}
.widget-chat ul li.message-from div {
  order: 2;
  background-color: #39a3f4;
  color: #ffffff;
  border: solid 1px #39a3f4;
}
.widget-chat ul li.message-own {
  justify-content: flex-end;
}
.widget-chat ul li.message-own img {
  margin-left: 0.5rem;
  order: 2;
}
.widget-chat ul li.message-own div {
  order: 1;
  background-color: #6ebc3b;
  color: #ffffff;
  border: solid 1px #6ebc3b;
}
.widget-chat .new-message {
  height: 40px;
  border-top: 1px solid #383838;
  color: #afafc0;
}
.widget-chat .new-message .message-attachment {
  display: inline-block;
  border-right: 1px solid #383838;
  width: 40px;
  line-height: 40px;
  height: 100%;
  text-align: center;
}
.widget-chat .new-message .message-attachment i {
  line-height: inherit;
  font-size: 24px;
}
.widget-chat .new-message .message-input {
  position: relative;
  top: -4px;
  width: calc(100% - 100px);
  display: inline-block;
}
.widget-chat .new-message .message-input input {
  border: 0 none;
  font-size: 14px;
  width: 100%;
  background: transparent;
  outline: 0 none;
  padding-left: 8px;
}

.widget-pricing-box {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 30px 0;
  text-align: center;
  background-color: #e1e6e9;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.widget-pricing-box .pricing-name {
  font-size: 24px;
}
.widget-pricing-box .pricing-fee {
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background-color: #39a3f4;
  color: #ffffff;
  width: 100px;
  height: 100px;
  margin: 30px auto;
  font-size: 22px;
  line-height: 100px;
}
.widget-pricing-box:hover {
  background-color: #39a3f4;
  color: #ffffff;
}
.widget-pricing-box:hover p {
  color: #ffffff;
}
.widget-pricing-box:hover .pricing-fee {
  background-color: #eaeeef;
  color: #39a3f4;
}

/* Login */
.login-body {
  height: 100vh;
  font-size: 16px;
  margin: 0;
}
.login-body .body-container {
  height: 7px;
  border-top: 7px solid #39a3f4;
}
.login-body .body-container .p-grid {
  margin-right: 0;
}
.login-body .right-side {
  width: 50%;
}
.login-body .left-side {
  height: 100%;
  padding-left: 100px;
  width: 50%;
}
.login-body .left-side img {
  padding: 100px 0 100px 0;
  width: 60px;
}
.login-body .left-side h1 {
  font-size: 80px;
  color: rgba(255, 255, 255, 0.87);
}
.login-body .left-side p {
  color: rgba(255, 255, 255, 0.87);
  padding-right: 50px;
  margin-top: 0;
  margin-bottom: 40px;
}

.login-wrapper {
  width: 400px;
  margin: 0 auto;
  text-align: center;
}
.login-wrapper .login-container {
  box-sizing: border-box;
  padding-top: 50%;
  text-align: center;
  color: #4f575b;
}
.login-wrapper .login-container .title {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.87);
  display: block;
  text-align: left;
  margin-bottom: 10px;
}
.login-wrapper .login-container .password-container {
  text-align: right;
}
.login-wrapper .login-container .p-link {
  color: #39a3f4;
  text-decoration: none;
  font-size: 16px;
  line-height: 32px;
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.2s;
}
.login-wrapper .login-container .p-link:hover {
  border-bottom-color: #39a3f4;
}
.login-wrapper .login-container input {
  width: 100%;
  font-size: 1em;
}
.login-wrapper .login-container .p-button {
  width: 100%;
  font-size: 16px;
}

@media (max-width: 64em) {
  .login-body {
    text-align: center;
    background-size: cover;
  }
  .login-body .left-side {
    height: 300px;
    padding-left: 25px;
    width: 100%;
  }
  .login-body .left-side img {
    padding: 20px 0 20px 0;
  }
  .login-body .left-side p {
    display: none;
  }
  .login-body .right-side {
    height: 100%;
    width: 100%;
  }

  .login-wrapper {
    width: 320px;
  }
  .login-wrapper .login-container {
    padding-top: 25%;
  }
}
@media (max-width: 40em) {
  .login-body .left-side {
    height: 200px;
    padding-left: 25px;
    text-align: center;
    width: 100%;
  }
  .login-body .left-side img {
    padding: 20px 0 20px 0;
  }
  .login-body .left-side h1 {
    font-size: 40px;
  }
  .login-body .left-side p {
    display: none;
  }
  .login-body .right-side {
    height: calc(100% - 250px);
    width: 100%;
  }

  .login-wrapper {
    width: 320px;
  }
  .login-wrapper .login-container {
    padding-top: 25%;
  }
}
/* Exception */
.exception-body {
  height: 100vh;
  font-size: 16px;
  margin: 0;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
}
.exception-body .exception-container {
  height: 100%;
  padding-left: 100px;
}
.exception-body .exception-container img {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin: 100px 0;
}
.exception-body .exception-container h1 {
  font-size: 80px;
  color: rgba(255, 255, 255, 0.87);
}
.exception-body .exception-container p {
  color: rgba(255, 255, 255, 0.87);
  margin-top: 0;
  margin-bottom: 40px;
}

@media (max-width: 1280px) {
  .exception-body {
    background-size: cover;
    text-align: center;
  }
  .exception-body .exception-container {
    padding-left: 0;
    padding-top: 200px;
  }
  .exception-body .exception-container img {
    margin-top: 0;
  }
  .exception-body .exception-container h1 {
    font-size: 40px;
  }
  .exception-body .exception-container p {
    padding: 0 25px;
  }
}
/* Dashboard */
.dashboard {
  margin: 0;
}
.dashboard > .p-col-12 {
  padding: 0.75rem;
}
.dashboard .overview-box {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 15px;
  position: relative;
  min-height: 100px;
  overflow: hidden;
}
.dashboard .overview-box h1 {
  font-size: 12px;
  color: #ffffff;
  margin: 0;
}
.dashboard .overview-box img {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.dashboard .overview-box .overview-value {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}
.dashboard .overview-box .overview-ratio {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #ffffff;
}
.dashboard .overview-box .overview-ratio .overview-direction {
  display: inline-block;
  text-align: center;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
}
.dashboard .overview-box .overview-ratio .overview-direction i {
  vertical-align: middle;
  font-size: inherit;
}
.dashboard .overview-box .overview-ratio .overview-ratio-value {
  display: inline-block;
  height: 20px;
  padding: 0 4px;
}
.dashboard .overview-box.overview-box-1 {
  background-image: linear-gradient(to bottom, #39a3f4 1%, #279cf5 99%);
}
.dashboard .overview-box.overview-box-1 .overview-direction {
  color: #39a3f4;
}
.dashboard .overview-box.overview-box-1 .overview-ratio-value {
  background-color: #1164a3;
  color: #39a3f4;
}
.dashboard .overview-box.overview-box-2 {
  background-image: linear-gradient(to bottom, #76be45, #66b92d);
}
.dashboard .overview-box.overview-box-2 .overview-direction {
  color: #76be45;
}
.dashboard .overview-box.overview-box-2 .overview-ratio-value {
  background-color: #447d1d;
  color: #76be45;
}
.dashboard .overview-box.overview-box-3 {
  background-image: linear-gradient(to bottom, #dea242, #d5942e);
}
.dashboard .overview-box.overview-box-3 .overview-direction {
  color: #dea242;
}
.dashboard .overview-box.overview-box-3 .overview-ratio-value {
  background-color: #90631d;
  color: #dea242;
}
.dashboard .overview-box.overview-box-4 {
  background-image: linear-gradient(to bottom, #d67161, #d65c49);
}
.dashboard .overview-box.overview-box-4 .overview-direction {
  color: #d67161;
}
.dashboard .overview-box.overview-box-4 .overview-ratio-value {
  background-color: #943728;
  color: #d67161;
}
.dashboard .disk-spaces .p-panel-content {
  padding: 2em;
}
.dashboard .disk-spaces .p-panel-content span {
  display: block;
  margin-bottom: 20px;
}
.dashboard .disk-spaces .p-panel-content .p-progressbar {
  margin-bottom: 40px;
  height: 5px;
}
.dashboard .task-list {
  overflow: hidden;
}
.dashboard .task-list .p-panel-content {
  min-height: 350px;
  padding: 0;
}
.dashboard .task-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dashboard .task-list ul li {
  padding: 10px 15px;
  border-bottom: 1px solid #383838;
}
.dashboard .task-list ul li:last-child {
  border-bottom: 0 none;
}
.dashboard .task-list ul .p-checkbox {
  vertical-align: middle;
  margin-right: 6px;
}
.dashboard .task-list ul .task-name {
  vertical-align: middle;
}
.dashboard .task-list ul i {
  color: rgba(255, 255, 255, 0.6);
  float: right;
  font-size: 20px;
  margin-top: 2px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.dashboard .contact-form {
  overflow: hidden;
}
.dashboard .contact-form .p-panel .p-panel-content {
  min-height: 350px;
}
.dashboard .contact-form .p-col-12 {
  padding: 10px 15px;
}
.dashboard .contact-form .p-col-12:last-child {
  padding-bottom: 0;
}
.dashboard .contacts {
  overflow: hidden;
}
.dashboard .contacts .p-panel .p-panel-content {
  min-height: 350px;
  padding: 0px !important;
}
.dashboard .contacts ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dashboard .contacts ul li {
  border-bottom: 1px solid #383838;
  padding: 10px 15px;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: block;
  border-radius: 3px;
  transition: background-color 0.2s;
}
.dashboard .contacts ul li img {
  float: left;
  margin-right: 8px;
}
.dashboard .contacts ul li .contact-info {
  float: left;
}
.dashboard .contacts ul li .contact-info .name {
  display: block;
  margin-top: 4px;
  font-size: 14px;
}
.dashboard .contacts ul li .contact-info .location {
  margin-top: 4px;
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}
.dashboard .contacts ul li .contact-actions {
  float: right;
  padding-top: 12px;
}
.dashboard .contacts ul li .contact-actions button:last-child {
  margin-left: 6px;
}
.dashboard .contacts ul li:last-child {
  border: 0;
}
.dashboard .chat .p-panel-content {
  padding: 0 !important;
}
.dashboard .chat .sales-panel {
  margin-top: 15px;
}
.dashboard .chat .sales-panel .card {
  padding: 0;
  margin: 0;
}
.dashboard .chat ul {
  padding: 12px;
  margin: 0;
  list-style-type: none;
}
.dashboard .chat ul li {
  padding: 6px 0;
}
.dashboard .chat ul li img {
  width: 36px;
  float: left;
}
.dashboard .chat ul li span {
  padding: 6px 12px;
  float: left;
  display: inline-block;
  margin: 4px 0;
  border-radius: 3px;
}
.dashboard .chat ul li.message-from img, .dashboard .chat ul li.message-from span {
  float: left;
}
.dashboard .chat ul li.message-from img {
  margin-right: 8px;
}
.dashboard .chat ul li.message-from span {
  background-color: #39a3f4;
  color: #ffffff;
}
.dashboard .chat ul li.message-own img, .dashboard .chat ul li.message-own span {
  float: right;
}
.dashboard .chat ul li.message-own img {
  margin-left: 8px;
}
.dashboard .chat ul li.message-own span {
  background-color: #dee2e6;
  color: #000000;
}
.dashboard .chat .new-message {
  height: 40px;
  border-top: 1px solid #383838;
  color: #afafc0;
}
.dashboard .chat .new-message .message-attachment {
  display: inline-block;
  border-right: 1px solid #383838;
  width: 40px;
  line-height: 40px;
  height: 100%;
  text-align: center;
}
.dashboard .chat .new-message .message-attachment i {
  line-height: inherit;
  font-size: 20px;
}
.dashboard .chat .new-message .message-input {
  position: relative;
  top: -4px;
  width: calc(100% - 100px);
  display: inline-block;
}
.dashboard .chat .new-message .message-input input {
  border: 0 none;
  font-size: 14px;
  width: 100%;
  background-color: transparent;
  outline: 0 none;
  padding-left: 8px;
  box-shadow: none;
}
.dashboard .timeline {
  margin: 0;
}
.dashboard .timeline > .p-grid .p-col-3 {
  font-size: 14px;
  position: relative;
  border-right: 1px solid #383838;
}
.dashboard .timeline > .p-grid .p-col-3 i {
  font-size: 24px;
  position: absolute;
  right: -16px;
}
.dashboard .timeline > .p-grid .p-col-9 {
  padding-left: 1.5em;
}
.dashboard .timeline > .p-grid .p-col-9 .event-text {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  display: block;
  padding-bottom: 20px;
}
.dashboard .timeline > .p-grid .p-col-9 .event-content img {
  width: 100%;
}
.dashboard .user-card {
  border: 1px solid #383838;
  padding: 0;
}
.dashboard .user-card .user-card-header {
  height: 100px;
  overflow: hidden;
  position: relative;
}
.dashboard .user-card .user-card-header img {
  width: 100%;
}
.dashboard .user-card .user-card-header .p-button {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 32px;
  right: 24px;
}
.dashboard .user-card .user-card-content {
  min-height: 340px;
  background-color: transparent;
  position: relative;
}
.dashboard .user-card .user-card-content img {
  position: absolute;
  top: -90px;
  left: 24px;
}
.dashboard .user-card .user-card-content .user-card-name {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.87);
  position: absolute;
  top: -60px;
  margin-left: 110px;
  font-weight: 700;
  white-space: nowrap;
}
.dashboard .user-card .user-card-content .user-detail {
  text-align: left;
}
.dashboard .user-card .user-card-content .user-detail ul {
  padding: 0px 0 32px 0;
  margin: 0;
  list-style-type: none;
}
.dashboard .user-card .user-card-content .user-detail ul li {
  padding: 16px 24px;
  border-top: 1px solid #383838;
}
.dashboard .user-card .user-card-content .user-detail ul li:last-child {
  border-bottom: 1px solid #383838;
}
.dashboard .user-card .user-card-content .user-detail ul li i {
  font-size: 24px;
  margin-right: 8px;
  width: 32px;
  vertical-align: middle;
}
.dashboard .user-card .user-card-content .user-detail ul li .project-title {
  font-weight: 700;
  margin-right: 8px;
}
.dashboard .user-card .user-card-content .user-detail ul li .project-detail {
  color: rgba(255, 255, 255, 0.6);
}
.dashboard .user-card .user-card-content .user-detail ul li .project-progressbar {
  width: 100px;
  background-color: #383838;
  float: right;
  margin-top: 12px;
}
.dashboard .user-card .user-card-content .user-detail ul li .project-progressbar .project-progressbar-value {
  border-radius: 3px;
  background-color: #39a3f4;
  height: 4px;
}
.dashboard .map img {
  width: 100%;
}
.dashboard .status-title {
  margin-bottom: 0.714em;
  font-size: 1.429em;
  text-align: center;
}
.dashboard .circle1 {
  width: 10.714em;
  height: 10.714em;
  border: 10px solid #383838;
  border-right-color: #6ebc3b;
  border-top-color: #6ebc3b;
  border-bottom-color: #6ebc3b;
  border-left-color: #6ebc3b;
  border-radius: 50%;
  margin: auto;
  border-left-color: #383838;
}
.dashboard .circle1 i {
  position: relative;
  font-size: 24px;
  left: 50%;
  top: 20%;
  width: 36px;
  height: 36px;
  margin-left: -20px;
  color: #6ebc3b;
  text-align: center;
}
.dashboard .circle1 span {
  width: 79px;
  height: 50px;
  position: relative;
  vertical-align: middle;
  text-align: center;
  top: 40%;
  left: 50%;
  margin-left: -35px;
  color: #6ebc3b;
  font-weight: bold;
  font-size: 30px;
}
.dashboard .circle2 {
  width: 10.714em;
  height: 10.714em;
  border: 10px solid #383838;
  border-right-color: #f6a821;
  border-top-color: #f6a821;
  border-bottom-color: #f6a821;
  border-left-color: #f6a821;
  border-radius: 50%;
  margin: auto;
  border-bottom-color: #383838;
  border-left-color: #383838;
  border-right-color: #383838;
}
.dashboard .circle2 i {
  position: relative;
  font-size: 24px;
  left: 50%;
  top: 20%;
  width: 36px;
  height: 36px;
  margin-left: -20px;
  color: #f6a821;
  text-align: center;
}
.dashboard .circle2 span {
  width: 79px;
  height: 50px;
  position: relative;
  vertical-align: middle;
  text-align: center;
  top: 40%;
  left: 50%;
  margin-left: -35px;
  color: #f6a821;
  font-weight: bold;
  font-size: 30px;
}
.dashboard .circle3 {
  width: 10.714em;
  height: 10.714em;
  border: 10px solid #383838;
  border-right-color: #039ade;
  border-top-color: #039ade;
  border-bottom-color: #039ade;
  border-left-color: #039ade;
  border-radius: 50%;
  margin: auto;
  border-bottom-color: #383838;
  border-right-color: #383838;
}
.dashboard .circle3 i {
  position: relative;
  font-size: 24px;
  left: 50%;
  top: 20%;
  width: 36px;
  height: 36px;
  margin-left: -20px;
  color: #039ade;
  text-align: center;
}
.dashboard .circle3 span {
  width: 79px;
  height: 50px;
  position: relative;
  vertical-align: middle;
  text-align: center;
  top: 40%;
  left: 50%;
  margin-left: -35px;
  color: #039ade;
  font-weight: bold;
  font-size: 30px;
}
.dashboard .circle4 {
  border-left-color: #383838;
  width: 10.714em;
  height: 10.714em;
  border: 10px solid #383838;
  border-right-color: #d66351;
  border-top-color: #d66351;
  border-bottom-color: #d66351;
  border-left-color: #d66351;
  border-radius: 50%;
  margin: auto;
}
.dashboard .circle4 i {
  position: relative;
  font-size: 24px;
  left: 50%;
  top: 20%;
  width: 36px;
  height: 36px;
  margin-left: -20px;
  color: #d66351;
  text-align: center;
}
.dashboard .circle4 span {
  width: 79px;
  height: 50px;
  position: relative;
  vertical-align: middle;
  text-align: center;
  top: 40%;
  left: 50%;
  margin-left: -35px;
  color: #d66351;
  font-weight: bold;
  font-size: 30px;
}
.dashboard .circle-panel .p-panel-titlebar {
  text-align: center;
}
.dashboard .circle-panel .p-panel-content {
  padding: 0;
}
.dashboard .circle-panel .p-grid > div {
  padding: 2em 0;
}
.dashboard .circle-panel div.p-col-12:not(:last-child) {
  border-right: 1px solid #383838;
}
.dashboard .p-panel {
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
  .dashboard .circle-panel div.p-col-12:not(:last-child) {
    border-right: 0 none;
  }
}
@media screen and (max-width: 960px) {
  .dashboard .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
.dashboard .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .dashboard .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
    border-bottom: 1px solid #dee2e6;
  }
  .dashboard .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }
  .dashboard .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4rem 1rem -0.4rem -0.4rem;
    font-weight: bold;
  }
  .dashboard .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
    margin-top: 0.5rem;
  }
}
/* Add your customizations of layout here */

