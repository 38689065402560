/* source-sans-pro-300 - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-300.eot"); /* IE9 Compat Modes */
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url("../fonts/source-sans-pro-v11-latin-ext_latin-300.eot#iefix") format('embedded-opentype'), /* IE6-IE8 */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-300.woff2") format('woff2'), /* Super Modern Browsers */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-300.woff") format('woff'), /* Modern Browsers */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-300.ttf") format('truetype'), /* Safari, Android, iOS */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-300.svg#SourceSansPro") format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.eot#iefix") format('embedded-opentype'), /* IE6-IE8 */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.woff2") format('woff2'), /* Super Modern Browsers */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.woff") format('woff'), /* Modern Browsers */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.ttf") format('truetype'), /* Safari, Android, iOS */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-regular.svg#SourceSansPro") format('svg'); /* Legacy iOS */
}
/* source-sans-pro-italic - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.eot"); /* IE9 Compat Modes */
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
       url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.eot#iefix") format('embedded-opentype'), /* IE6-IE8 */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.woff2") format('woff2'), /* Super Modern Browsers */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.woff") format('woff'), /* Modern Browsers */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.ttf") format('truetype'), /* Safari, Android, iOS */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-italic.svg#SourceSansPro") format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin-ext_latin */
@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/source-sans-pro-v11-latin-ext_latin-700.eot"); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
       url("../fonts/source-sans-pro-v11-latin-ext_latin-700.eot#iefix") format('embedded-opentype'), /* IE6-IE8 */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-700.woff2") format('woff2'), /* Super Modern Browsers */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-700.woff") format('woff'), /* Modern Browsers */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-700.ttf") format('truetype'), /* Safari, Android, iOS */
       url("../fonts/source-sans-pro-v11-latin-ext_latin-700.svg#SourceSansPro") format('svg'); /* Legacy iOS */
}
