.layout-wrapper {
    .topbar {
        background-color: $primaryColor;
        padding: 10px 16px;
        height: 50px;
        box-sizing: border-box;
        position: fixed;
        top: 0px;
        width: 100%;
        z-index: 102;
        @include border-box();

        .menu-button {
            display: none;
            cursor: pointer;
            vertical-align: top;
            height: 50px;
            width: 50px;
            line-height: 50px;
            text-align: center;
            margin-top: -10px;
            margin-left: 30px;
            color: #ffffff;
            user-select: none;
            transition: background-color $transitionDuration;

            i {
                font-size: 28px;
                line-height: inherit;
            }

            &:hover {
                background-color: lighten($primaryColor, 10%);
            }
        }

        .topbar-themeswitcher {
            position: relative;
            float: right;
            top: 8px;
            right: 10px;

            .p-inputswitch {
                .p-inputswitch-slider {
                    background-color: #ffffff;
                    border-color: #d8d8d8;
                    &:before {
                        background-color: #d8d8d8;
                    }
                }

                &.p-inputswitch-checked {
                    .p-inputswitch-slider {
                        background-color:  #243447;
                        border-color: #243447;
                        &:before {
                            background-color: #000000;
                        }
                    }
                }
            }
        }

        .topbar-search {
            padding: 0;
            position: relative;
            float: right;
            margin: 0 10px 0 0;
            top: 3px;

            input {
                display: inline-block;
                border: 0 none;
                font-size: $fontSize;
                background: lighten($primaryColor, 10%);
                outline: 0 none;
                color: $primaryTextColor;
                width: 175px;
                padding: 4px 20px 4px 8px;
                margin: 0px;
                border-radius: $borderRadius;

                &::-webkit-input-placeholder { color:$primaryTextColor;; opacity: .7; transition: opacity $transitionDuration;}
                &:-moz-placeholder { color:$primaryTextColor;; opacity: .7; transition: opacity $transitionDuration;}
                &::-moz-placeholder { color:$primaryTextColor;; opacity: .7; transition: opacity $transitionDuration;}
                &:-ms-input-placeholder { color:$primaryTextColor;; opacity: .7; transition: opacity $transitionDuration;}
            }

            .pi {
                font-size: 16px;
                position: absolute;
                top: 4px;
                right: 5px;
                color: $primaryTextColor;
            }
        }

        .logo-link {
            display: inline-block;
            height: 100%;
            width: 185px;

            .logo {
                height: 30px;
            }
        }

        .profile {
            float: right;
            text-align: right;
            margin-top: -5px;
            font-weight: 700;
            cursor: pointer;

            img {
                vertical-align: middle;
                width: 40px;
                margin-right: 8px;
            }

            .username {
                vertical-align: middle;
                margin-right: 8px;
                color: $primaryTextColor;
            }

            .pi {
                font-size: 16px;
                vertical-align: middle;
                color: $primaryTextColor;
            }
        }

        .topbar-menu {
            display: none;
            position: absolute;
            cursor: pointer;
            right: 0px;
            top: 50px;
            width: 250px;
            list-style-type: none;
            padding: 0;
            margin: 0;
            background-color: $primaryColor;
            border-top: 1px solid darken($primaryColor, 10%);
            animation-duration: $transitionDuration;

            &.topbar-menu-visible {
                display: block;
            }

            > li {
                button {
                    width: 100%;
                    font-family: $fontFamily;
                    font-size: $fontSize;
                    color: $primaryTextColor;
                    padding: 10px 16px;
                    display: block;

                    i {
                        font-size: 16px;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    span {
                        margin-left: 6px;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    img {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .topbar-badge {
                        float: right;
                        background-color: $primaryTextColor;
                        display: block;
                        color: $primaryColor;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        text-align: center;
                        margin-top: 1px;
                        border-radius: 50%;
                    }

                    &:hover {
                        background-color: lighten($primaryColor, 10%);
                    }
                }

                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    button {
                        padding-left: 32px;
                    }

                    &.layout-submenu-container-enter {
                        max-height: 0;
                    }

                    &.layout-submenu-container-enter-active {
                        overflow: hidden;
                        max-height: 1000px;
                        transition: max-height 1s ease-in-out;
                    }

                    &.layout-submenu-container-enter-done {
                        transform: none;
                    }

                    &.layout-submenu-container-exit {
                        max-height: 1000px;
                    }

                    &.layout-submenu-container-exit-active {
                        overflow: hidden;
                        max-height: 0;
                        transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
                    }

                }

                &.topbar-search-item {
                    border-top: 1px solid darken($primaryColor, 10%);
                    padding: 10px 16px;
                    display: none;

                    .topbar-search {
                        float: none;
                        display: block;
                        width: 100%;
                        top: 0;

                        input {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &.layout-overlay, &.layout-static {
        .topbar {
            box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);

            .menu-button {
              display: inline-block;
            }
        }
    }

    &.layout-slim {
        .topbar {
            box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
        }
    }
}
